import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Explore from "../components/explore"

import yepmed from "../images/yepmed.png"
import crossdev from "../images/crossdev.png"
import restartmed from "../images/restartmed.png"

const CoopPage = () => (
  <div id="indexPage">
    <Header/>
    <div className="content-pad">
      <h2>التآزر مع مشاريع ENI CBC MED</h2>
      <p>
        أحد المبادئ الأساسية التي يستند عليها مشروع FISH MED NET هو إنشاء بيئة تعاونية بين أصحاب المصلحة المختلفين، وتوفير الرؤية اللازمة لأطراف المعنية للجهات الخارجية. تهدف جميع هذه الجهود إلى خلق عالم أفضل للعيش، وبالمثل، يسعى المشروع إلى التآزر مع مشاريع أخرى من ENI CBC MED التي تشترك في أهداف مماثلة. يمكنكم زيارة هذه الصفحة لعرض المشاريع المختلفة التي تتعاون مع FISH MED NET ومعرفة المزيد عن الأهداف المستدامة للأمم المتحدة المشتركة مع كل مشروع.
      </p>

      <div className="coop">
        <a href="https://www.enicbcmed.eu/projects/yep-med"><img src={yepmed}/></a>
        <p>
          يهدف مشروع توظيف الشباب في الموانئ المتوسطية إلى تعزيز مشاركة الشباب من خلال تحسين مستوى الخدمات اللوجستية بالموانئ و
          زيادة فرص العمل المحلية من خلال التدريب وتعزيز دور الشركات الصغيرة والمتوسطة العاملة في إطار الموانئ. يهدف المشروع أيضا إلى
          إنشاء شراكات تعاونية وطنية وعابرة للحدود بين الجهات الفاعلة المشاركة في لوجستيات الموانئ عبر حوض البحر الأبيض المتوسط.
        </p>
      </div>
      <hr/>

      <div className="coop">
        <a href="https://www.hiddenmediterranean.net/en/"><img src={crossdev}/></a>
        <p>
          مشروع CROSSDEV هو مشروع تم بناؤه لرفع مستوى الوجهات السياحية الأقل شهرة من خلال الثقافة الاجتماعية والاقتصادية
          والثقافة المحلية وتراثها في جميع أنحاء البحر الأبيض المتوسط.
          ويطور المشروع تعاونا بين البلدان من خلال تعزيز سياسات وممارسات السياحة المستدامة بهدف تحسين سبل عيش أصحاب
          المصلحة المعنيين.
        </p>
      </div>
      <hr/>

      <div className="coop">
        <a href="https://medsustainabletourism.net/"><img src={restartmed}/></a>
        <p>
          إعادة تشغيل الطب! يساهم المشروع في التنمية الاقتصادية والاجتماعية لمنطقة البحر الأبيض المتوسط ، وتنشيط اقتصاد السياحة
          المستدامة بعد الوباء من خلال 4 استراتيجيات رئيسية: التنويع ، والابتكار ، وإزالة التوابل ، وتنمية الاقتصاد المحلي. ويهدف
          المشروع إلى إعادة تشكيل الطلب السياحي، ودعم السلطات في تطوير سياسات السياحة المستدامة، وتشجيع التعاون بين أصحاب
          المصلحة في مجال السياحة.
        </p>
      </div>

      <h2>للإطلاع على آخر مستجداتنا</h2>
    </div>
    <Explore slug="index"/>

    <Footer/>
  </div>
)

export default CoopPage
